
import { throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { AppSettings } from '../app.settings';
import { DI } from '../state/core';
import { SharedInfoService } from '../partsCatalog/sharedInfo.service';
import { FeatureChecker } from '../shared/feature-checker.service';

@Injectable()
export class DataPlateService extends DI.AutoDependencyInjector {
  @DI.Inject(() => HttpClient)
  private http: HttpClient;
  
  @DI.Inject(() => SharedInfoService)
  private sharedInfoService: SharedInfoService;

    @DI.Inject(() => FeatureChecker)
    private featureChecker: FeatureChecker;

  constructor(_injector: Injector) {
    super(_injector);
  }

   public async getBillOfMaterials(esn: string, type: string, noBOM = false): Promise<any> {
    if (noBOM) {
      return this.getNoBOMEngineResponse();
    }
    if (esn == "26649036") {
      return await this.http.get("../../assets/26649036.json")
        .toPromise()
        .then((res:Response) => res)
        .catch((error:any) => console.log(error));

    } else {
      let _newUrl = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/v2/esnInfo/${esn}?esnType=${type}`;
      let _oldUrl = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/esnInfo?esn=${esn}&esnType=${type}`;
      let _url = await this.isRestructureFlagEnabled(type) ? _newUrl : _oldUrl;
      return await this.http.get(_url)
        .toPromise()
        .then((res: Response) => res)
        .catch(this.handleError);
    }
  }

  public async getDataplate(esn: string, type: string): Promise<any> {
    let _newUrl = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/v2/esnInfo/${esn}/dataplate?esnType=${type}`;
    let _oldUrl = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/esnDataplate?esn=${esn}&type=${type}`;
    let _url = await this.isRestructureFlagEnabled(type) ? _newUrl : _oldUrl;
    return this.http.get(_url)
      .pipe(catchError(this.handleError)).toPromise();
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.message}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  public getInjectorTrims(esn: string): Promise<any> {

    let url = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/getInjectorTrims?esn=${esn.trim()}`;
    return this.http.get(url)
          .toPromise()
          .then((res:Response) => res);
  }

  public isRestructureFlagEnabled(esnType: string): Promise<any> {
    return this.featureChecker.applyFeature('ENABLE_RESTRUCTURE_DATA', { esnType });
  }

  private getNoBOMEngineResponse(): { [key: string]: any } {
    return {
      optionList: [],
      systemOptions: [],
      engineGraphic: null,
      esn: this.sharedInfoService.esnObject.number,
      mbom: this.sharedInfoService.esnObject.esnType,
      serviceModel: (this.sharedInfoService.esnObject.description || '').toUpperCase()
    }
  }
}
