import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { ROUTES } from './app.routes';
import { SessionInterceptor } from './interceptors/session.interceptor';
import { AppState } from './app.service';
import { CookieService } from './cookie-popup/cookie.service';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { HeaderService } from './shared/header/header.service';
import { NotificationService } from './shared/header/notification.service';
import { FooterComponent } from './shared/footer/footer.component';
import { GenericNotificationComponent } from './shared/generic-notification/generic-notification.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { LoginSharedService } from './login/login-shared.service';
import { LogoutService } from './logout/logout.service';
import { ErrorShared } from './error/ErrorShared';
import { AuthGuard } from './guard.service';
import { BreadcrumbComponent } from './breadcrumbs/breadcrumbs.component';
import { BreadcrumbsService } from './breadcrumbs/breadcrumbs.service';
import { OptionDetailModule } from './optionDetail/optionDetail.module';
import { ToasterModule } from 'angular2-toaster';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { PopoverModule } from 'ngx-popover';
import { SharedModule, DialogModule, OverlayPanelModule } from 'primeng/primeng';
import { CartService } from './cart/cart.service';
import { CartSharedModule } from './cartShared/cartShared.module';
import { ResponsiveModule } from 'ngx-responsive';
import { MatProgressBarModule, MatProgressSpinnerModule } from '@angular/material';
import { TicketCompleteService } from './ticket-complete/ticket-complete.service';
import { TicketCompleteComponent } from './ticket-complete/ticket-complete.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookiePopupComponent } from './cookie-popup/cookie.popup.component';
import { SharedPartDetail } from './optionDetail/partDetail/sharedPartDetails';
import { IntegrationComponent } from './integration/integration.component';
import { IntegrationService } from './integration/integration.service';
import { IACTranslationsService } from './iacTranslations/iacTranslations.service';
import { TreeManipulator } from './tree-node/service';

import { GsnAuthGuard } from './gsn-guard.service';
import { SharedModule as CustomSharedModule } from './shared.module';
import { DealerNewslettersComponent } from './dealer-newsletters/dealer-newsletters.component';
import { ResourceCacheService } from './interceptors/resource-cache.service';
import { LoginAuthGuard } from './auth.guard';
import { AccessCheckComponent } from './shared/check-access/check-access.component';
import { SAMLComponent } from './saml/saml.component';
import { APP_BASE_HREF } from '@angular/common';
import { ErrorModule } from './error/error.module';
import { AngularMaterialModule } from './angular-material.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { SharedInfoService } from './partsCatalog/sharedInfo.service';
import { PowerGenSharedInfoService } from './power-gen/powerGenSharedInfo.service';
import { MSTSharedService } from './mechanical-service-tools/mst-shared.service';
import { BlobStorageService } from './cms-editor/services/api-services/cms-blob.service';
import { SharingServices } from './cms-editor/services/sharing-service';
import { NgxsModule, NgxsModuleOptions } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '../environments/environment';
import { ApplicationState } from './state/app.state';
import { EngineBOMState } from './partsCatalog/state/engine-bom.state';
import { OptionDetailComponentState } from './optionDetail/state/option-detail.state';
import { ApplicationStatePluginModule } from './state/app-plugins.module';
import { RouterState } from './breadcrumbs/state/router.state';
import { HttpModule } from '@angular/http';

import * as FeatureFunctions from './shared/feature-functions';
import { FEATURE_FUNCTIONS } from './shared/feature-checker.service';
import { DataPlateService } from './dataplate/dataplate.service';

export const STORAGE_SEPARATOR = '|';
export const STORAGE_PREFIX = 'ng2-webstorage';

export const NGXS_MODULE_OPTS: NgxsModuleOptions = {
    developmentMode: !environment.production,
    compatibility: { strictContentSecurityPolicy: true },
    selectorOptions: {
        suppressErrors: false,
        injectContainerState: false
    }
};

const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    AppState
];
@NgModule({
    declarations: [
        AppComponent,
        CookiePopupComponent,
        HeaderComponent,
        HomeComponent,
        FooterComponent,
        LoginComponent,
        BreadcrumbComponent,
        GenericNotificationComponent,
        IntegrationComponent,
        TicketCompleteComponent,
        DealerNewslettersComponent,
        AccessCheckComponent,
        SAMLComponent
    ],
    imports: [
        MatProgressBarModule, AngularMaterialModule, AngularFontAwesomeModule,
        MatProgressSpinnerModule,
        BrowserModule, CartSharedModule.forRoot(),
        ErrorModule, PopoverModule,
        FormsModule, BrowserAnimationsModule, OverlayPanelModule,
        DialogModule, OptionDetailModule, RouterModule,
        SharedModule, ReactiveFormsModule, HttpModule,
        ToasterModule, ResponsiveModule, HttpClientModule,
        HttpClientModule, RouterModule, SharedModule, ReactiveFormsModule,
        ToasterModule.forRoot(), ResponsiveModule.forRoot(), ROUTES,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }), CustomSharedModule,
        NgxWebstorageModule.forRoot({ prefix: STORAGE_PREFIX, separator: STORAGE_SEPARATOR }),
        NgxsModule.forRoot([
            ApplicationState, EngineBOMState, OptionDetailComponentState,
            RouterState
        ], NGXS_MODULE_OPTS),
        NgxsReduxDevtoolsPluginModule.forRoot({
            name: 'IAC-Store',
            disabled: environment.production
        }),
        ApplicationStatePluginModule.forRoot()
    ],
    // expose our Services and Providers into Angular's dependency injection
    providers: [
        SharedPartDetail, APP_PROVIDERS, LoginService, ErrorShared, CookieService,
        IntegrationService, IACTranslationsService,
        LogoutService, LoginSharedService, BreadcrumbsService, NotificationService, CartService, HeaderService,
        TicketCompleteService, BlobStorageService, SharingServices, DataPlateService,
        { provide: APP_BASE_HREF, useValue: '/' }, AuthGuard, GsnAuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
        ResourceCacheService, LoginAuthGuard,
        TreeManipulator, SharedInfoService, PowerGenSharedInfoService, MSTSharedService,
        { provide: FEATURE_FUNCTIONS, useClass: FeatureFunctions.CheckObjectFieldFunction, multi: true},
        { provide: FEATURE_FUNCTIONS, useClass: FeatureFunctions.TrueFunction, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(readonly loginShared: LoginSharedService) {
        this.loginShared.registeredFeatures.set('dealer-newsletters', ['DEALER_NEWS', 'IACDataServices']);
        this.loginShared.registeredFeatures.set('add-upfits', ['UPFITS_UPLOAD', 'IACDataServices']);
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
