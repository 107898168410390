var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { AppSettings } from "../app.settings";
import { DataPlateService } from "../dataplate/dataplate.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../dataplate/dataplate.service";
var SbomService = /** @class */ (function () {
    function SbomService(_httpClient, dataplateservice) {
        this._httpClient = _httpClient;
        this.dataplateservice = dataplateservice;
        this._loaderEvent = new BehaviorSubject(null);
        this.setBlob = this.setBlob.bind(this);
        this.setErrorResponse = this.setErrorResponse.bind(this);
    }
    Object.defineProperty(SbomService.prototype, "loaderEvent$", {
        get: function () {
            return this._loaderEvent.filter(function (event) { return !!event; });
        },
        enumerable: true,
        configurable: true
    });
    SbomService.prototype.newDownloadSbom = function (_sharedService) {
        this._loaderEvent.next({ type: "pending" });
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var res = _sharedService.currentEsnInfo;
        var url = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/v2/esnInfo/" + res.esn + "/sbom?esnType=" + res.mbom;
        return this._httpClient.get(url, { headers: headers, responseType: 'blob' }).toPromise()
            .then(this.setBlob).catch(this.setErrorResponse);
    };
    SbomService.prototype.downloadSbom = function (_sharedService) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, url, res, preCheck, sbomParameters, engineType;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataplateservice.isRestructureFlagEnabled(_sharedService.currentEsnInfo.mbom)];
                    case 1:
                        if (_a.sent()) {
                            return [2 /*return*/, this.newDownloadSbom(_sharedService)];
                        }
                        this._loaderEvent.next({ type: "pending" });
                        headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                        url = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/getSbom";
                        res = _sharedService.currentEsnInfo;
                        preCheck = res.buildDate ? new Date(res.buildDate).getFullYear() : 2014;
                        sbomParameters = {
                            "engineBuildDate": res.buildDate,
                            "esn": res.esn
                        };
                        if (preCheck >= 2015) {
                            if (res.kitSets) {
                                sbomParameters['kitSets'] = res.kitSets;
                            }
                            else {
                                engineType = (res.mbom === "mbom" ? "mbom_" : (res.mbom === "package" ? "package_" : "")) + "options";
                                sbomParameters['optionList'] = res.optionList;
                                sbomParameters['cplNbr'] = res.cpl;
                                sbomParameters['enginePlantCode'] = res.enginePlantCode;
                                sbomParameters['engineType'] = engineType;
                            }
                            if (res.gasketSets) {
                                sbomParameters['gasketSets'] = res.gasketSets;
                            }
                            else {
                                sbomParameters['configuration'] = res.marketingConfig;
                            }
                        }
                        return [2 /*return*/, this._httpClient.post(url, JSON.stringify(sbomParameters), { headers: headers, responseType: 'blob' }).toPromise()
                                .then((this.setBlob)).catch(this.setErrorResponse)];
                }
            });
        });
    };
    SbomService.prototype.setBlob = function (blob) {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, 'serviceBOM.xlsx');
        }
        else {
            var url = window.URL.createObjectURL(blob);
            var anchor = document.createElement('a');
            anchor.href = url;
            anchor.style.visibility = 'hidden';
            anchor.download = 'serviceBOM.xlsx';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }
        this._loaderEvent.next({ type: "done" });
    };
    SbomService.prototype.setErrorResponse = function (errorResponse) {
        var _this = this;
        var reader = new FileReader();
        reader.onload = function (e) {
            var error = JSON.parse(e.target.result);
            _this._loaderEvent.next({ type: "error", message: error });
        };
        reader.readAsText(errorResponse._body);
    };
    SbomService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SbomService_Factory() { return new SbomService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DataPlateService)); }, token: SbomService, providedIn: "root" });
    return SbomService;
}());
export { SbomService };
