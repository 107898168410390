import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AppSettings } from "../app.settings";
import { DataPlateService } from "../dataplate/dataplate.service";
import { SharedInfoService } from "../partsCatalog/sharedInfo.service";

@Injectable({ providedIn: 'root' })
export class SbomService {
    private _loaderEvent: BehaviorSubject<DownloadEvent> = new BehaviorSubject<DownloadEvent>(null);

    constructor(private _httpClient: HttpClient, private dataplateservice: DataPlateService) {
        this.setBlob = this.setBlob.bind(this);
        this.setErrorResponse = this.setErrorResponse.bind(this);
    }

    get loaderEvent$(): Observable<DownloadEvent> {
        return this._loaderEvent.filter(event => !!event);
    }

    newDownloadSbom(_sharedService: SharedInfoService) {
        this._loaderEvent.next({ type: "pending" });
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const res = _sharedService.currentEsnInfo;
        const url = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/v2/esnInfo/${res.esn}/sbom?esnType=${res.mbom}`;
        return this._httpClient.get(url, { headers, responseType: 'blob' }).toPromise()
            .then(this.setBlob).catch(this.setErrorResponse);
    }

    public async downloadSbom(_sharedService: SharedInfoService): Promise<any> {
        if (await this.dataplateservice.isRestructureFlagEnabled(_sharedService.currentEsnInfo.mbom)) {
            return this.newDownloadSbom(_sharedService);
        }

        this._loaderEvent.next({ type: "pending" });
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/getSbom`;
        const res = _sharedService.currentEsnInfo;
        const preCheck = res.buildDate ? new Date(res.buildDate).getFullYear() : 2014;
        const sbomParameters = {
            "engineBuildDate": res.buildDate,
            "esn": res.esn
        };

        if (preCheck >= 2015) {
            if (res.kitSets) {
                sbomParameters['kitSets'] = res.kitSets;
            } else {
                let engineType = (res.mbom === "mbom" ? "mbom_" : (res.mbom === "package" ? "package_" : "")) + "options";
                sbomParameters['optionList'] = res.optionList;
                sbomParameters['cplNbr'] = res.cpl;
                sbomParameters['enginePlantCode'] = res.enginePlantCode;
                sbomParameters['engineType'] = engineType;
            }

            if (res.gasketSets) {
                sbomParameters['gasketSets'] = res.gasketSets;
            }
            else {
                sbomParameters['configuration'] = res.marketingConfig;
            }
        }

        return this._httpClient.post(url, JSON.stringify(sbomParameters), { headers, responseType: 'blob' }).toPromise()
            .then((this.setBlob)).catch(this.setErrorResponse);
    }

    setBlob(blob: Blob) {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, 'serviceBOM.xlsx');
        } else {
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.style.visibility = 'hidden';
            anchor.download = 'serviceBOM.xlsx';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }
        this._loaderEvent.next({ type: "done" });
    }

    setErrorResponse(errorResponse: any) {
        const reader = new FileReader();
        reader.onload = (e: Event) => {
            const error = JSON.parse((<any>e.target).result);
            this._loaderEvent.next({ type: "error", message: error });
        };
        reader.readAsText(errorResponse._body);
    }
}

export interface DownloadEvent {
    type: 'pending' | 'done' | 'error';
    message?: any;
}
