var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { throwError } from 'rxjs';
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AppSettings } from '../app.settings';
import { DI } from '../state/core';
import { SharedInfoService } from '../partsCatalog/sharedInfo.service';
import { FeatureChecker } from '../shared/feature-checker.service';
var ɵ0 = function () { return HttpClient; }, ɵ1 = function () { return SharedInfoService; }, ɵ2 = function () { return FeatureChecker; };
var DataPlateService = /** @class */ (function (_super) {
    __extends(DataPlateService, _super);
    function DataPlateService(_injector) {
        return _super.call(this, _injector) || this;
    }
    DataPlateService.prototype.getBillOfMaterials = function (esn, type, noBOM) {
        if (noBOM === void 0) { noBOM = false; }
        return __awaiter(this, void 0, void 0, function () {
            var _newUrl, _oldUrl, _url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (noBOM) {
                            return [2 /*return*/, this.getNoBOMEngineResponse()];
                        }
                        if (!(esn == "26649036")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.http.get("../../assets/26649036.json")
                                .toPromise()
                                .then(function (res) { return res; })
                                .catch(function (error) { return console.log(error); })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        _newUrl = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/v2/esnInfo/" + esn + "?esnType=" + type;
                        _oldUrl = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/esnInfo?esn=" + esn + "&esnType=" + type;
                        return [4 /*yield*/, this.isRestructureFlagEnabled(type)];
                    case 3:
                        _url = (_a.sent()) ? _newUrl : _oldUrl;
                        return [4 /*yield*/, this.http.get(_url)
                                .toPromise()
                                .then(function (res) { return res; })
                                .catch(this.handleError)];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DataPlateService.prototype.getDataplate = function (esn, type) {
        return __awaiter(this, void 0, void 0, function () {
            var _newUrl, _oldUrl, _url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _newUrl = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/v2/esnInfo/" + esn + "/dataplate?esnType=" + type;
                        _oldUrl = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/esnDataplate?esn=" + esn + "&type=" + type;
                        return [4 /*yield*/, this.isRestructureFlagEnabled(type)];
                    case 1:
                        _url = (_a.sent()) ? _newUrl : _oldUrl;
                        return [2 /*return*/, this.http.get(_url)
                                .pipe(catchError(this.handleError)).toPromise()];
                }
            });
        });
    };
    DataPlateService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.message));
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };
    DataPlateService.prototype.getInjectorTrims = function (esn) {
        var url = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/getInjectorTrims?esn=" + esn.trim();
        return this.http.get(url)
            .toPromise()
            .then(function (res) { return res; });
    };
    DataPlateService.prototype.isRestructureFlagEnabled = function (esnType) {
        return this.featureChecker.applyFeature('ENABLE_RESTRUCTURE_DATA', { esnType: esnType });
    };
    DataPlateService.prototype.getNoBOMEngineResponse = function () {
        return {
            optionList: [],
            systemOptions: [],
            engineGraphic: null,
            esn: this.sharedInfoService.esnObject.number,
            mbom: this.sharedInfoService.esnObject.esnType,
            serviceModel: (this.sharedInfoService.esnObject.description || '').toUpperCase()
        };
    };
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", HttpClient)
    ], DataPlateService.prototype, "http", void 0);
    __decorate([
        DI.Inject(ɵ1),
        __metadata("design:type", SharedInfoService)
    ], DataPlateService.prototype, "sharedInfoService", void 0);
    __decorate([
        DI.Inject(ɵ2),
        __metadata("design:type", FeatureChecker)
    ], DataPlateService.prototype, "featureChecker", void 0);
    return DataPlateService;
}(DI.AutoDependencyInjector));
export { DataPlateService };
export { ɵ0, ɵ1, ɵ2 };
